import fb from '../config/constants'
import moment from 'moment'

export function loadTrends() {
  return fb.db
    .collection('trends')
    .doc('twitter')
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data()
      } else {
        console.log('NO TRENDS 4U')
      }
    })
    .catch(err => ({ trends: [], lastUpdated: null }))
}

export function watchTrends(callBack) {
  return fb.db
    .collection('trends')
    .doc('twitter')
    .onSnapshot(doc => {
      return callBack(doc.data())
    })
}
