import React from 'react';
import styled from 'styled-components';

export const AYCHWON = styled.h1`
  margin: 0;
  font-weight: bolder;
`;
export const Header = ({ header, children }) => (
  <div>
    <AYCHWON>{header}</AYCHWON>
    {children}
  </div>
)

export default Header;
