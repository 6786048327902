import React, { Component } from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import Layout from '../components/layout'
import Header from '../components/DisplayHeader'
import {
  StepNumber,
  Step,
  StepNumberWrapper,
  KeyWordHeader,
  Tip,
} from '../components/KeywordHeader'
import Moment from 'moment'
import Theme from '../utils/theme'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faWrench from '@fortawesome/fontawesome-free-solid/faWrench'
import faDatabase from '@fortawesome/fontawesome-free-solid/faDatabase'
import faUserSecret from '@fortawesome/fontawesome-free-solid/faUserSecret'
import { loadTrends, watchTrends } from '../fireHelpers/trends'
import axios from 'axios'

const API_URL = process.env.API_URL

const HeroLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const HeroSection = styled.section`
  margin: 1rem;
  min-width: 400px;
  flex: ${props => props.flex || 1};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const HeroContent = styled.section`
  margin: 1rem;
  min-width: 300px;
  display: flex;
  flex-direction: column;
`

const ExplainerSection = HeroContent.extend`
  margin: 0;
  width: 100%;
  background-color: ${Theme.palette.primary.light};
  color: white;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2em;
`

const Placeholder = styled.div`
  padding: 1em;
  width: 100%;
  height: 90px;
  border: 2px solid white;
  align-self: flex-start;
  font-size: 1em;
  background-color: ${Theme.palette.primary.light};
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: left;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: ${props => props.fs || 60}px;
  color: ${props => props.color || 'white'};
`

const AdText = styled.div`
  margin: 1em 0em 1em 1em;
`

const TrendList = styled.ol`
  overflow-x: auto;
  max-height: 380px;
`

class IndexPage2 extends Component {
  constructor(props) {
    super(props)
    this.state = { trends: [], lastUpdated: null }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      loadTrends().then(data => {
        this.sendRefreshSignal(data)
        this.unsubscribeTrends = watchTrends(trendData =>
          this.setState({
            trends: trendData.trends,
            lastUpdated: trendData.lastUpdated,
          })
        )
        return this.setState({
          trends: data.trends,
          lastUpdated: data.lastUpdated,
        })
      })
    }
  }

  componentWillUnmount() {
    this.unsubscribeTrends && this.unsubscribeTrends()
  }

  sendRefreshSignal = data => {
    if (
      data.lastUpdated &&
      Moment(data.lastUpdated.toDate()).diff(Moment().format(), 'minutes') < -1
    ) {
      axios
        .get(`${API_URL}/trends`)
        .then(response => {
          if (response.status === 200) {
            console.log('sent for updated trend data', response.data)
          }
        })
        .catch(err => {
          console.log('error trying to update trend data', err)
        })
    }
  }

  renderTrends = () => {
    return (
      <div>
        <h4>
          {'Last Updated: '}
          {Moment(this.state.lastUpdated.toDate()).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}{' '}
        </h4>
        <TrendList>
          {this.state.trends.map(trend => (
            <li key={trend.name}>
              <a href={trend.url} rel="noopener noreferrer" target="_blank">
                {trend.name}
              </a>
            </li>
          ))}
        </TrendList>
      </div>
    )
  }

  render() {
    return (
      <Layout>
        <HeroLayout>
          <HeroSection flex={2}>
            <Header header={'Brick by Brick'}>
              <p>{`LupaMerch is focused on providing an efficient set of tools
                 that make your print-on-demand listing process as seamless and
                  painless as possible. It all starts with our clever bullet builder.
                  Stay tuned for more tools Fall 2018`}</p>
            </Header>
            <HeroLayout>
              <ExplainerSection>
                <Placeholder>
                  <Icon icon={faWrench} />{' '}
                  <AdText>
                    {' '}
                    use the bullet builder to pen the perfect set of bullets{' '}
                  </AdText>
                </Placeholder>
                <Placeholder>
                  <Icon icon={faDatabase} />{' '}
                  <AdText>
                    {' '}
                    go pro to save and manage your growing collection of bullets
                  </AdText>
                </Placeholder>
                <Placeholder>
                  <Icon icon={faUserSecret} />{' '}
                  <AdText>
                    {' '}
                    Upload images to search for copy-cats across platforms{' '}
                    <span style={{ fontSize: '10px' }}>(in development)</span>
                  </AdText>
                </Placeholder>
              </ExplainerSection>
            </HeroLayout>
          </HeroSection>
          <HeroSection flex={3}>
            <Header header={'DASHBOARD'}>
              <p>
                {
                  'Stay up-to-date on trends, holiday countdowns and more to come!'
                }
              </p>
            </Header>
            <HeroLayout>
              <HeroContent>
                <Header header={'Trending'} />
                {this.state.lastUpdated && this.renderTrends()}
              </HeroContent>
              <HeroContent>
                <Header header={'Holiday Tracker'} />
                <KeyWordHeader>
                  {' '}
                  <StepNumber
                    size={5}
                    num={Moment('1225', 'MMDD').diff(
                      Moment().format('YYYYMMDD'),
                      'days'
                    )}
                  />
                  DAYS UNTIL CHRISTMAS
                </KeyWordHeader>
                <h4>
                  {Moment('1122', 'MMDD').diff(
                    Moment().format('YYYYMMDD'),
                    'days'
                  )}{' '}
                  {' DAYS UNTIL THANKSGIVING'}
                </h4>
                <h4>
                  {Moment('1031', 'MMDD').diff(
                    Moment().format('YYYYMMDD'),
                    'days'
                  )}{' '}
                  {' DAYS UNTIL HALLOWEEN'}
                </h4>
                <h4>
                  {Moment('20190704', 'YYYYMMDD').diff(
                    Moment().format('YYYYMMDD'),
                    'days'
                  )}{' '}
                  {' DAYS UNTIL INDEPENDENCE DAY'}
                </h4>
                <h4>
                  {Moment('20190116', 'YYYYMMDD').diff(
                    Moment().format('YYYYMMDD'),
                    'days'
                  )}{' '}
                  {' DAYS UNTIL 100th DAY OF SCHOOL'}
                </h4>
              </HeroContent>
            </HeroLayout>
          </HeroSection>
        </HeroLayout>
      </Layout>
    )
  }
}

// <Link to="/page-2/">Go to page 2</Link>

export default IndexPage2
